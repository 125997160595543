import styles from "./WidthHeightWrapper.css";
import classes from "classnames";
import { Children, cloneElement, Component } from "react";
import isBrowser from "../../../util/is-browser.js";
import { throttle } from "../../../util/limiters.js";

class WidthHeightWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0,
        };
        this.container = null;
        this.setContainer = this.setContainer.bind(this);
        this.resizeHandler = throttle(this.resizeHandler.bind(this), 50);
        this.setSize = this.setSize.bind(this);
    }

    componentDidMount() {
        if (isBrowser) {
            window.addEventListener("resize", this.resizeHandler);
            this.setSize();
        }
    }

    componentDidUpdate() {
        if (isBrowser) {
            this.setSize();
        }
    }

    componentWillUnmount() {
        if (isBrowser) {
            window.removeEventListener("resize", this.resizeHandler);
        }
    }

    setSize() {
        let size = this.container.getBoundingClientRect();
        let width = Math.round(size.width);
        let height = Math.round(size.height);

        if (width !== this.state.width || height !== this.state.height) {
            this.setState({
                width: width,
                height: height,
            });
        }
    }

    resizeHandler() {
        setTimeout(() => {
            this.setSize();
        }, 0);
    }

    setContainer(node) {
        this.container = node;
    }

    render() {
        const { children, className, ...props } = this.props;

        return (
            <div
                ref={this.setContainer}
                className={classes(className, styles.container)}
                {...props}
            >
                {Children.map(children, (element) =>
                    cloneElement(element, {
                        className: classes(
                            styles.child,
                            element.props.className
                        ),
                        width: this.state.width,
                        height: this.state.height,
                    })
                )}
            </div>
        );
    }
}
export default WidthHeightWrapper;
