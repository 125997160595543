import { Component } from "react";
import styles from "./TileGroup.css";
import classes from "classnames";
import Tile from "../Tile";

class TileGroup extends Component {
    getFeaturedClass(feature) {
        switch (feature) {
            case 1:
                return styles.oneFeature;
            case 2:
                return styles.twoFeature;
            case 3:
                return styles.threeFeature;
            default:
                return false;
        }
    }
    getRowClass(row) {
        switch (row) {
            case 1:
                return styles.oneUp;
            case 2:
                return styles.twoUp;
            case 3:
                return styles.threeUp;
            case 4:
                return styles.fourUp;

            default:
                return false;
        }
    }
    getGroupClass(group) {
        switch (group) {
            case "additionalTiles":
                return styles.additionalTiles;
            case "relatedTiles":
                return styles.relatedTiles;
            default:
                return false;
        }
    }
    render() {
        const { group, title, items, feature } = this.props;
        const featuredClass = this.getFeaturedClass(feature);
        let row = items && items.length;
        const rowClass = this.getRowClass(row);
        const groupClass = this.getGroupClass(group);

        return (
            <section
                className={classes(
                    styles.tileGroup,
                    featuredClass,
                    rowClass,
                    groupClass
                )}
            >
                {title && <span className={styles.title}>{title}</span>}
                <div className={styles.tiles}>
                    {items &&
                        items.map((props, i) => {
                            if (feature) {
                                props.isTextOver = true;
                                props.hideTeaser = false;
                            }

                            return (
                                <Tile
                                    key={i}
                                    {...props}
                                    className={styles.tile}
                                    ratio={row === 4 && "1:1"}
                                    // sizes set to match the sizes in the Tile in LibraryGridItems.
                                    // Not able to verify sizes locally
                                    sizes="(max-width: 372px) 75px, (max-width: 465px) 100px, (max-width: 599px) 112px, (max-width: 1167px) 100px, (max-width: 1311px) 112px, 100px"
                                />
                            );
                        })}
                </div>
            </section>
        );
    }
}

export default TileGroup;
