import styles from "./Tile.css";
import classes from "classnames";
import MediaBlock from "../MediaBlock";
import SmartLink from "../SmartLink";
import { Play as PlayIcon } from "@churchofjesuschrist/eden-icons";
import { fontSize42 } from "@churchofjesuschrist/eden-style-constants";

// TODO: cleanup logic

const Tile = ({
    className,
    hideTeaser,
    href,
    isFeature,
    isTextOver,
    isVideo,
    media,
    ratio,
    teaser,
    title,
}) => (
    <SmartLink
        className={classes(
            className,
            styles.tile,
            // TODO: check with Nathanial to find out what these do
            { [styles.overlay]: isTextOver },
            { [styles.featured]: isFeature }
        )}
        href={href}
    >
        <div
            className={classes(
                styles.mediaContainer,
                ratio === "1:1" && styles.square
            )}
        >
            {isVideo === "true" && (
                <div className={styles.isVideo}>
                    <PlayIcon className={styles.videoIcon} size={fontSize42} />
                </div>
            )}
            <MediaBlock {...media} />
        </div>
        <div className={styles.textContainer}>
            <span className={styles.title}>{title}</span>
            {!hideTeaser && <p className={styles.teaser}>{teaser}</p>}
        </div>
    </SmartLink>
);

export default Tile;
