// TODO: The below disabled eslint rules were done quickly to get the code to compile. They should be reviewed and re-enabled or corrected as necessary.
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Component } from "react";
import styles from "./MediaBlock.css";
import classes from "classnames";
import loadable from "@loadable/component";

import Image from "../Image";
import RenderHTML from "../RenderHTML";
import { Play as PlayIcon } from "@churchofjesuschrist/eden-icons";
import WidthHeightWrapper from "../WidthHeightWrapper";
import Loading from "../Loading";

import { fontSize42 } from "@churchofjesuschrist/eden-style-constants";

const VideoPlayer = loadable(
    () => import(/* webpackChunkName: 'video-player' */ "../VideoPlayer"),
    { fallback: <Loading /> }
);

class MediaBlock extends Component {
    static defaultProps = {
        image: {},
    };

    state = {
        play: false,
    };

    startVideo = () => this.setState({ play: !this.state.play });

    // TODO: cleanup logic
    renderImage() {
        const { image, video, copyright } = this.props;
        const { play } = this.state;

        if (video && !play) {
            return (
                <div onClick={this.startVideo} className={styles.posterFrame}>
                    {image.src && (
                        <Image
                            {...image}
                            placeholderClass={styles.placeholder}
                            className={styles.image}
                        />
                    )}
                    <PlayIcon size={fontSize42} className={styles.playButton} />
                    {copyright && (
                        <span className={styles.copyright}>{copyright}</span>
                    )}
                </div>
            );
        } else if (!video) {
            return (
                <div className={styles.imageContainer}>
                    {image.src && (
                        <Image
                            {...image}
                            placeholderClass={styles.placeholder}
                            className={styles.image}
                        />
                    )}
                    {copyright && (
                        <span className={styles.copyright}>{copyright}</span>
                    )}
                </div>
            );
        }
    }

    render() {
        const { className, caption, video } = this.props;
        const { play } = this.state;

        return (
            <figure className={classes(styles.mediaContainer, className)}>
                {video && play && (
                    <WidthHeightWrapper className={styles.widthHeightWrapper}>
                        <VideoPlayer className={styles.video} {...video} />
                    </WidthHeightWrapper>
                )}
                {this.renderImage()}

                {caption && (
                    <figcaption className={styles.caption}>
                        <RenderHTML html={caption} />
                    </figcaption>
                )}
            </figure>
        );
    }
}

export default MediaBlock;
