export const throttle = (fn, time = 50) => {
    let timer = null;

    function throttledFn(...args) {
        if (!timer) {
            timer = setTimeout(() => {
                fn(...args);
                timer = null;
            }, time);
        }
    }

    throttledFn.cancel = () => {
        clearTimeout(timer);
        timer = null;
    };

    return throttledFn;
};

export const ensureOnce = (fn, time = 50) => {
    let timer = null;

    const clearTimer = () => {
        timer = null;
    };

    return function (...args) {
        if (timer) {
            clearTimeout(timer);
        } else {
            timer = setTimeout(() => fn(...args), time);
            setTimeout(clearTimer, time);
        }
    };
};
