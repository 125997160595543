import { Component, Fragment } from "react";
import styles from "./Home.css";
import loadable from "@loadable/component";
import { Helmet } from "react-helmet";
import { get } from "@churchofjesuschrist/universal-env";

import ErrorPanel from "../../components/ErrorPanel";
import Frame from "../../components/Frame";
import Loading from "../../components/Loading";
import TileGroup from "../../components/TileGroup";
import LibraryGridItems from "../../components/LibraryGridItems";

const { APP_URL } = get();

const VideoStream = loadable(
    () =>
        import(
            /* webpackChunkName: 'video-stream' */ "../../components/VideoStream"
        ),
    { fallback: <Loading className={styles.videoStreamPlaceholder} /> }
);

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = { isClient: false };
    }

    componentDidMount() {
        document.dispatchEvent(new Event("DOMContentLoaded"));
        document.dispatchEvent(new CustomEvent("checkcontext"));

        this.setState({ isClient: true });
    }

    render() {
        const {
            featureItems,
            additionalItems,
            streamItems,
            lang,
            library: { canonicalUrl, fallbackEntryImage, sections, title },
            libraryStatus,
            homeStatus,
            showArchivedContent,
        } = this.props;

        const getSections = () => {
            if (this.state.isClient && !showArchivedContent) {
                // filter out archived-content
                return sections?.map((section) => {
                    const filteredEntries = section?.entries?.filter(
                        (entry) => {
                            return entry.uri !== "/study/archived-content";
                        }
                    );

                    return { ...section, entries: filteredEntries };
                });
            } else {
                return sections;
            }
        };

        return (
            <div className={styles.home}>
                <Helmet>
                    <title>{title}</title>
                    {canonicalUrl && (
                        <link
                            key="canonical"
                            rel="canonical"
                            href={`${APP_URL}${canonicalUrl}`}
                        />
                    )}
                </Helmet>
                <Frame type="narrowTop" id="main">
                    {!homeStatus && (
                        <Fragment>
                            {streamItems && streamItems.streamEnabled && (
                                <VideoStream item={streamItems} />
                            )}
                            {featureItems && (
                                <TileGroup
                                    feature={featureItems.length}
                                    items={featureItems}
                                />
                            )}
                            {additionalItems && (
                                <TileGroup
                                    group="additionalTiles"
                                    items={additionalItems}
                                />
                            )}
                        </Fragment>
                    )}
                    {libraryStatus > 200 ? (
                        <ErrorPanel
                            className={styles.errorPanel}
                            id={"home"}
                            status={libraryStatus}
                            {...this.props}
                        />
                    ) : (
                        <section className={styles.gospelLibrary}>
                            <div className={styles.statusTitle}>{title}</div>
                            <LibraryGridItems
                                fallbackEntryImage={fallbackEntryImage}
                                lang={lang}
                                sections={getSections()}
                            />
                        </section>
                    )}
                </Frame>
            </div>
        );
    }
}

export default Home;
